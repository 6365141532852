import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import Img from 'components/Img'

import enhancer from './hooks'

const Hero = ({ onClickSidebar }) => {
  return (
    <section className="financial-health__hero">
      <div className="container">
        <div className="financial-health__hero-content">
          <div className="financial-health__hero-text">
            <Typography
              as="h2"
              variant="headline4"
              className="financial-health__hero-title"
            >
              Mais que educação. <br />Saúde Financeira para todos.
            </Typography>
            <Typography as="p" variant="body1" className="financial-health__hero-description">
            A Onze oferece um hub completo de saúde financeira que está revolucionando a vida de pessoas e empresas com um cuidado justo
            </Typography>

            <Button
              onClick={onClickSidebar}
              label="Agende agora uma conversa"
              iconId="right-arrow"
              iconPosition="right"
              buttonStyle="outline"
              customClass="financial-health__hero-button"
            />
          </div>

          <div className="financial-health__hero-svg">
            <Img className="financial-health__hero-svg-dots" src='/Dots.svg' aria-hidden="true" />
          </div>
        </div>

        <div className="financial-health__hero-image">
          <Img className="financial-health__hero-image-phone" src='/rebranding-financial-health-4.png' alt="phone" />
        </div>
      </div>
    </section>
  )
}


export default enhancer(Hero)