import Button from 'rv-components-onze/src/Button'
import Header from 'components/Header'
import FooterNew from 'components/FooterNew'
import Hero from './components/Hero'
import Benefits from './components/Benefits'
import Checkup from './components/Checkup'
import DreamBenefits from 'components/DreamBenefits'
import Specialists from './components/Specialists'
import SectionLearn from './components/SectionLearn'
import SectionReasons from './components/SectionReasons'
import ConversationForm from 'components/ConversationSectionForm'
import SectionForm from 'components/SectionForm'
import Sidebar from 'components/Sidebar'
import Menu from 'components/Menu'
import { textBlock, cardsList1, cardsList2 } from './mock'

import enhancer from './hooks'

const FinancialHealth = ({ onClickSidebar }) => {
  return (
    <div className="financial-health">
      <Header />
      <Menu />
      <Sidebar>
        <SectionForm customClass="sidebar" />
      </Sidebar>
      <Hero />

      <DreamBenefits
        imageSrc="/rebranding-simples-aderir-img2.png"
        title="Saúde financeira é a base da qualidade de vida. Com múltiplas ferramentas, tenha um cuidado integral para o seu time"
        bulletList={textBlock}
      />
      <Checkup />
      {/* <Benefits /> */}
      <SectionReasons
        title="Saúde financeira <span>data driven:</span> apoio total para o RH"
        cardsList={cardsList1}
      />
      <div className="container financial-health__hero-button--center">
        <Button
          onClick={onClickSidebar}
          label="Quero montar o plano do meu time com a Onze!"
          buttonStyle="outline"
          customClass="financial-health__hero-button"
        />
      </div>
      <SectionReasons
        title="Por que incentivar os seus colaboradores a cuidarem da saúde financeira?"
        cardsList={cardsList2}
      />
      <ConversationForm />
      <FooterNew />
    </div>
  )
}

export default enhancer(FinancialHealth)
