import Typography from 'rv-components-onze/src/Typography'

import Card from '../CardReasons'

const SectionReasons = ({ title, cardsList }) => {
  return (
    <section className="section-reasons">
      <div className="container section-reasons__content">
        <div className="section-reasons__header">
          <Typography
            as="h2"
            variant="headline5"
            className="section-reasons__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        <div className="section-reasons__body">
          {cardsList.map(({ title, description, footer = '' }, index) => (
            <Card key={`card-${index}`} title={title} footer={footer}>
              {description}
            </Card>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionReasons
