import Typography from 'rv-components-onze/src/Typography'

const cards = [
  {
    title: 'Check-ups regulares',
    description: 'Com algumas perguntas, os usuários recebem recomendações estratégicas para melhorar sua saúde financeira.'
  },
  {
    title: 'Consultoria individualizada',
    description: 'Pelo app, os usuários podem agendar consultas de 30 minutos sempre que precisarem tirar dúvidas sobre finanças que não contam para ninguém.'
  },
  {
    title: 'Onze Descomplica',
    description: 'Através da curadoria dos nossos especialistas, você tem mais de 900 vídeos sobre finanças para fazer consultas rápidas pelo app.'
  },
]


const Benefits = () => {

  return (
    <section className="health-benefits container">
      <div className="health-benefits__description">
        <Typography as="h2" variant="headline5" className="health-benefits__description-title">
          A saúde financeira do seu colaborador impacta diretamente na saúde da sua empresa
        </Typography>
      </div>

      <div className="health-benefits__cards">
        {cards.map(({ title, description }, index) => (
          <div className="health-benefits__card" key={`${title}-${index}`}>
            <Typography as="p" variant="subtitle" className="health-benefits__card-title" weight="bold">
              {title}
            </Typography>
            <Typography as="p" variant="body1" className="health-benefits__card-description">
              {description}
            </Typography>
          </div>
        ))}
      </div>
    </section>
  )
}


export default Benefits