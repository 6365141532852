import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import Img from 'components/Img'

import enhancer from './hooks'

const SectionLearn = ({ onClickSidebar }) => {
    return (
        <section className='section-learn'>
            <div className='section-learn__container'>
                <div className='section-learn__text'>
                    <Typography as='h1' variant='headline4' className='section-learn__text-title'>
                        Um jeito simples e inovador de aprender
                    </Typography>

                    <Typography as='h2' variant='subtitle' weight="normal" className='section-learn__text-subtitle'>
                        + de 900 vídeos sobre finanças pessoais
                    </Typography>
                    <Typography as='p' variant='body1' className='section-learn__text-paragraph'>
                        recomendados de forma personalizada para descomplicar a vida financeira dos seus colaboradores.
                    </Typography>

                    <Button
                        onClick={onClickSidebar}
                        label="Agende uma conversa"
                        buttonStyle='outline'
                        iconId='right-arrow'
                        iconPosition='right'
                        customClass='section-learn__text-button'
                    />
                </div>
                <div className='section-learn__image-container'>
                    <Img src='/img-videos@2x.png' />
                </div>
            </div>

        </section>
    )
}

export default enhancer(SectionLearn)