import Typography from 'rv-components-onze/src/Typography'
import Img from 'components/Img'
import { isMobile } from 'utils/getDevice'


const DreamBenefitsBlock = ({ title, description }) => {
  return (
    <div className="dream-benefits__block block">
      <Typography
        as="h4"
        variant="subtitle"
        weight="bold"
        className="block__title">
        {title}
      </Typography>
      <div className="block__description-content">
        <span className="block__icon" />
        <Typography as="p" variant="body1" className="block__description">
          {description}
        </Typography>
      </div>
    </div>
  )
}

const DreamBenefits = ({
  imageSrc,
  title,
  bulletList
}) => {
  return (
    <section className="dream-benefits">
      {
        isMobile() && (
          <Img
          src={imageSrc}
          alt="phone"
          className="dream-benefits__image-mobile"
        />
        )
      }
      <div className="dream-benefits__header">
        <div className="container">
          <Img
            src={imageSrc}
            alt="phone"
            className="dream-benefits__image"
          />
          <Typography
            as="h3"
            variant="headline4"
            className="dream-benefits__title">
              {title}
          </Typography>
        </div>
      </div>
      <div className="container">
        {bulletList.map((block, idx) => (
          <DreamBenefitsBlock
            key={`${block.title}-${idx}`}
            title={block.title}
            description={block.description}
          />
        ))}
      </div>
    </section>
  )
}

export default DreamBenefits
