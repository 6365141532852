export const textBlock = [
  {
    title: 'Check-ups regulares',
    description: 'Com algumas perguntas, seus talentos recebem recomendações estratégicas para melhorar a saúde financeira.'
  },
  {
    title: 'Consultoria individualizada',
    description: 'Acompanhamento personalizado com planejadores certificados que cuidam das finanças do seu time com agilidade e transparência, sem nenhum tipo de interesse comercial.'
  },
  {
    title: 'Palestras',
    description: 'Conversas online ou presenciais com os colaboradores sobre assuntos diversos relacionados à vida financeira. Uma ajuda a mais para suas estratégias de engajamento.'
  },
  {
    title: 'Onze Descomplica',
    description: 'Um curso completo sobre finanças pessoais feito sob medida pelos nossos especialistas para apoiar seu time a transformar as finanças de maneira prática e acessível.'
  },
]
export const cardsList1 = [
  {
    title: 'Relatório de Saúde financeira exclusivo',
    description: 'Entenda os reais desafios financeiros dos seus colaboradores para acabar com o estresse financeiro na sua empresa.',
  },
  {
    title: 'Antecipe a raíz dos principais problemas do seu time',
    description: '68% dos colaboradores têm problemas de saúde mental e física por culpa das preocupações financeiras (Pesquisa Onze, 2023).',
  },
  {
    title: 'Garanta ações de engajamento totalmente personalizadas',
    description: 'Com os insights exclusivos da nossa plataforma,  tenha acesso a um calendário customizado com campanhas e palestras direcionadas às necessidades do seu time.',
  },
]
export const cardsList2 = [
  {
    title: '#1 Cuidado integral com a saúde',
    description: '71% dos colaboradores relatam impactos no bem-estar e qualidade de vida devido às preocupações financeiras, com casos de insônia, ansiedade e até depressão.',
    footer: '*Pesquisa de Saúde Financeira da Onze (2023), com mais de 8 mil entrevistados',
  },
  {
    title: '#2 Redução de custos',
    description: 'Colaboradores relatam que o estresse financeiro impacta de 3 a 5 horas de trabalho semanais, o que pode causar um prejuízo financeiro de milhões de reais para as empresas em um ano.',
    footer: '*Pesquisa Onze, 2023',
  },
  {
    title: '#3 Ganho de produtividade',
    description: '68% dos colaboradores sentem impactos diretos do estresse financeiro na rotina de trabalho - desde falta de foco até impaciência com colegas de trabalho.',
    footer: '*Pesquisa Onze, 2023',
  },
]