import Typography from 'rv-components-onze/src/Typography'

const CardReasons = ({ title, children, footer }) => {
  return (
    <div className="card-reason">
      <Typography
        as="h4"
        variant="subtitle"
        className="card-reason__title"
        weight="bold"
      >
        {title}
      </Typography>

      <div className="card-reason__line" />

      <Typography
        as="p"
        variant="body1"
        className="card-reason__content"
      >
        {children}
      </Typography>
      <Typography
        as="p"
        variant="caption"
        className="card-reason__footer"
      >
        {footer}
      </Typography>
    </div>
  );
}

export default CardReasons