import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'

import enhancer from './hooks'

const Checkup = ({ onClickSidebar }) => {

  return (
    <section className="financial-health-checkup">
      {/* <div className="container">
        <Typography as="h3" variant="headline4" className="financial-health-checkup__title">
          Check-up completo e personalizado
        </Typography>
        <Typography as="p" variant="subtitle" weight="normal" className="financial-health-checkup__caption">
          Ferramenta exclusiva Onze para avaliar periodicamente a saúde financeira dos colaboradores. Com total privacidade.
        </Typography>
      </div> */}

      <div className="financial-health-checkup__background">
        <div className="financial-health-checkup__description">
          <div className="financial-health-checkup__description-item">
            <Typography as="p" variant="headline5" className="financial-health-checkup__subtitle">
              O colaborador <span>entende</span>
            </Typography>

            <ul className="financial-health-checkup__description-list">
              <Typography as="li" variant="body1" className="list-item -secondary">
                Se está endividado demais
              </Typography>
              <Typography as="li" variant="body1" className="list-item -secondary">
                Qual o nível de proteção ideal contra emergências
              </Typography>
              <Typography as="li" variant="body1" className="list-item -secondary">
                Seu potencial real de economia e investimento
              </Typography>
              <Typography as="li" variant="body1" className="list-item -secondary">
                Passos práticos para mudar sua saúde financeira 
              </Typography>
            </ul>
          </div>

          <div className="financial-health-checkup__description-item">
            <Typography as="p" variant="headline5" className="financial-health-checkup__subtitle financial-health-checkup__subtitle--second">
              O RH <span>identifica</span>
            </Typography>

            <ul className="financial-health-checkup__description-list">
              <Typography as="li" variant="body1" weight="light" className="list-item -primary">
                As principais causas do estresse financeiro
              </Typography>
              <Typography as="li" variant="body1"  className="list-item -primary">
                Os impactos para a empresa
              </Typography>
              <Typography as="li" variant="body1"  className="list-item -primary">
                Possíveis desafios de engajamento e clima
              </Typography>
              <Typography as="li" variant="body1"  className="list-item -primary">
                Como atuar para reduzir esses efeitos
              </Typography>
            </ul>
          </div>

          <div className="financial-health-checkup__button-wrapper">
            <Button
              onClick={onClickSidebar}
              label="Meu time merece uma revolução financeira"
              iconId="right-arrow"
              iconPosition="right"
              customClass="financial-health-checkup__button"
            />
          </div>
        </div>
      </div>
    </section>
  )
}


export default enhancer(Checkup)