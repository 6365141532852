import Button from 'rv-components-onze/src/Button'
import Typography from 'rv-components-onze/src/Typography'
import Img from 'components/Img'

import enhancer from './hooks'


const Specialists = ({ onClickSidebar }) => {

  return (
    <section className="financial-health__specialists">
      <div className="financial-health__specialists-description">
        <Typography as="h3" variant="headline5" className="financial-health__specialists-title">
          Especialistas financeiros à disposição
        </Typography>
        <Typography as="p" variant="body1" className="financial-health__specialists-text">
          Após o check-up, os colaboradores têm acesso de forma individual e sob demanda a uma solução de "Telemedicina Financeira". Por lá, eles entram em contato com especialistas treinados para ajudar em diversos aspectos da vida financeira.
        </Typography>

        <Button
          label="Agende uma conversa"
          iconId="right-arrow"
          iconPosition="right"
          buttonStyle="outline"
          customClass="financial-health__specialists-button"
          onClick={onClickSidebar}
        />
      </div>

      <Img
        className="financial-health__specialists-image"
        src="/img-especialistas.png"
        alt="Imagem de pessoas em uma ligação"
      />
    </section>
  )
}


export default enhancer(Specialists)